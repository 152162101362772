import React from 'react';
import styles from './index.module.scss';
import Signature from './signature';

function App(props) {
  const {
    onClose,
    onSuccess,
  } = props;
  return (
    <div className={styles.modalWrapper}>
      <Signature onClose={onClose} onSuccess={onSuccess} />
    </div >
  );
};

export default App;