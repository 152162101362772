import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Grid,
  Radio,
  Button,
  Toast,
} from 'antd-mobile';
import {
  putSignApi,
  postCfcaSendProjectCodeApi,
} from '../../apis/index';

function App(props) {
  const {
    id,
    contractId,
    partyInfo,
    submitInfo,
    onCompleted,
  } = props;
  const [form] = Form.useForm();
  const [timer, setTimer] = useState();
  const [isAgreeRules, setIsAgreeRules] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    const userInfo = window.$store.get('loginInfo') || { user: {} };
    const { user = {} } = userInfo;
    // 手机号脱敏
    user.mobile = user.mobile.replace(/^(1[3-9][0-9])\d{4}(\d{4}$)/, '$1****$2');
    setUser(user);
  };

  const verify = (data) => {
    if (!isAgreeRules) {
      Toast.show({
        content: '请先阅读并同意用户协议',
      });
      return;
    }

    const values = form.getFieldsValue();

    if (!values.checkCode) {
      Toast.show({
        content: '请输入验证码',
      });
      return;
    }

    putSignApi(id, {
      terminal: navigator.userAgent,
      checkCode: values.checkCode,
      cfcaSignFileReqs: submitInfo,
    }).then(() => {
      onCompleted();
    }).catch(() => { })
  };

  const startSmsCodeTimer = () => {
    let count = 60;
    let intervalTimer = setInterval(() => {
      if (count < 0) {
        clearInterval(intervalTimer);
      } else {
        count -= 1;
        setTimer(count);
      }
    }, 1000);
  };

  const sendMessage = () => {
    postCfcaSendProjectCodeApi({
      contractId: contractId,
      enterpriseName: partyInfo.contractPartyType === '02' ? partyInfo.enterpriseName : '',
    }).then((data) => {
      // 发送验证码成功，开始倒计时
      Toast.show({
        content: '发送成功，请注意查收',
      });
      startSmsCodeTimer();
    }).catch(() => { });
  };

  const renderSmsCode = () => {
    if (timer >= 0) {
      return (<div>{timer}s</div>);
    } else {
      return (<a onClick={sendMessage}>发送</a>)
    }
  };

  return (
    <>
      <Form
        form={form}
        mode='card'
        layout='horizontal'
        onFinish={verify}
        footer={
          <>
            <Form.Subscribe to={[]}>
              {() => (
                <div
                  style={{
                    marginBottom: '24px',
                    fontSize: '12px',
                    color: 'var(--adm-color-weak)',
                  }}
                >
                  <Grid columns={20}>
                    <Grid.Item span={2}>
                      <Radio
                        checked={isAgreeRules}
                        onClick={() => { setIsAgreeRules(!isAgreeRules); }}
                        style={{
                          '--icon-size': '18px',
                          '--font-size': '14px',
                          '--gap': '6px',
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item span={18}>
                      我已阅读并同意<a href="#">《服务协议》</a><a href="#">《隐私政策》</a><br />我同意采集人脸识别数据用于认证服务
                    </Grid.Item>
                  </Grid>
                </div>
              )}
            </Form.Subscribe>
            <Button block type='submit' color='primary' size='large'>
              同意并继续
            </Button>
          </>
        }
      >
        <Form.Item label='手机号'>
          <Input disabled value={user.mobile} placeholder='请输入' />
        </Form.Item>
        <Form.Item
          name="checkCode"
          label='验证码'
          rules={[{ required: true, message: '请输入正确的验证码' }]}
          extra={renderSmsCode()}
        >
          <Input placeholder='请输入' type="tel" maxLength={6} />
        </Form.Item>
      </Form >
    </>
  );
};

export default App;