import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';

import {
  Grid,
  Popup,
  Toast,
  Tabs,
  Button,
  Dialog,
  Swiper,
  Form,
  DotLoading,
  TextArea,
} from 'antd-mobile';
import {
  BankcardOutline,
  EditSOutline,
} from 'antd-mobile-icons';
import {
  getSealListApi,
  updateRefuseContractApi,
  getContractInfoByIdApi,
  getFileUrlListByFileIdApi,
  getFileListByBusinessApi,
  getContractInfoByPartyIdApi,
} from '../../apis/index';
import {
  getFileDownloadApi,
} from '../../apis/special.js';
import {
  canSignContract,
} from '../../utils/const.js';
import CreateSignature from '../../components/signature/index.js'

import PDFActionCard from './pdf/index.js';
import Detail from './detial/index';

import styles from './index.module.scss';

const sealStyle = {
  offsetLeft: 200,
  offsetTop: 400,
  width: 60,
  height: 20,
};
const enterpriseSealStyle = {
  ...sealStyle,
  height: 60,
};

function App() {
  const [form] = Form.useForm();
  const { id } = useParams();
  const swiperRef = useRef(null);
  const loginInfo = window.$store.get('loginInfo');
  const [loadStatusArray, setLoadStatusArray] = useState([]);
  const [pageSealList, setPageSealList] = useState([]);
  const [pdfOffsetTop, setPdfOffsetTop] = useState([]);

  // 【合同】签署人信息[Object]
  const [currentPartyInfo, setCurrentPartyInfo] = useState({});
  // 【合同】信息[Object]
  const [contractInfo, setContractInfo] = useState({});
  // 【合同】文件摘要信息[Array]
  const [contractFileList, setContractFileList] = useState(null);
  // 【合同】文件详情信息[Object]
  const [contractFileDetail, setContractFileDetail] = useState([]);

  // 【盖章】列表 [Array]
  const [sealList, setSealList] = useState([]);
  // 【盖章】已盖章数据[Object]
  const [sealedList, setSealedList] = useState([]);

  // 【弹窗】 详情
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  // 【弹窗】 拒绝
  const [refuseModalVisible, setRefuseModalVisible] = useState(false);
  // 【弹窗】 选择个人签名
  const [sealModalVisible, setSealModalVisible] = useState(false);
  // 【弹窗】 创建个人印章的弹窗
  const [createSealModalVisible, setCreateSealModalVisible] = useState(false);

  // 当前展示的合同文件index
  const [activeIndex, setActiveIndex] = useState(0);
  // PDF展示完整
  const [loadCompleted, setLoadCompleted] = useState(false);

  const navigate = useNavigate();

  // 页面初始化
  const init = async () => {
    try {
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
      setLoadStatusArray([]);

      // 通过签署人ID查询合同信息
      const currentPartyInfo = await getContractInfoByPartyIdApi(id);
      setCurrentPartyInfo(currentPartyInfo);

      // 如果登录的用户实名和合同签署的用户实名不一致，弹窗处理
      const isSamePeople = currentPartyInfo.userName === loginInfo.user.standardName;
      if (!isSamePeople) {
        // 如果不是一个人，暂时就不要继续查了
        Dialog.clear();
        Dialog.alert({
          maskClickable: false,
          title: '注意',
          content: (
            <div className={styles.samePeopleModal}>
              <div>发件方要求您以<span>{currentPartyInfo.userName}</span>的身份签署合同。</div>
              <div>这和您实名的身份信息不符。</div>
              <div>您当前账号的实名身份为<span>{loginInfo.user.standardName}</span></div>
              <div>于{loginInfo.user.createTime}完成实名</div>
            </div>
          ),
          confirmText: '不是，联系发件方更改签署信息',
          onConfirm: () => {
            navigate(`/signResult/${id}/fail`, {
              replace: true,
            });
          },
        })
        return;
      }

      // 1. 根据id查详情
      const contractInfo = await getContractInfoByIdApi(currentPartyInfo.contractID);

      // 2. 根据合同id查合同附件
      const fileList = await getFileListByBusinessApi(contractInfo.id);

      // 3.通过文件ID获取pdf详情
      const files = await getAllContractFileDetails(fileList);

      setContractInfo(contractInfo);
      setContractFileList(fileList);
      setContractFileDetail(files);

      Toast.show({
        icon: 'loading',
        duration: 0,
      });
    } catch (err) {
    }
  };

  // 【done】获取每一个PDF文件的详情
  const getAllContractFileDetails = (fileList) => {
    try {
      const w = window.innerWidth;
      const fetchs = fileList.map((i, index) => {
        return new Promise((res, rej) => {
          getFileUrlListByFileIdApi(i.objectName).then(i => {
            let h = 0;
            let halfHeight = 0;
            // 数据格式化
            i.map(item => {
              halfHeight = Math.floor((w * item.height) / item.width / 2);
              item.displayHeight = halfHeight * 2;
              item.halfHeight = halfHeight;
              item.top = h;
              item.bottom = h + item.displayHeight;
              h += item.displayHeight;
              return item;
            });

            res({
              scale: i[0].width / w, // 缩放比例， 用来做最后提交数据的等比例缩放
              displayHeight: h, // PDF总高度
              displayWidth: w,
              totalPages: i.length,
              pages: i,
            });
          }).catch(e => {
            rej(e);
          });
        });
      });
      return Promise.all(fetchs, (results) => results);
    } catch (err) {
      // 请求失败，应该上报数据
    }
  };

  // 【done】开始签名的流程
  const selectSeal = (sign) => {
    let temp = pageSealList;
    temp[activeIndex] = sign;
    setPageSealList([...temp])
    // 这个盖章的默认位置应该从子组件中获得
    const currentPdfScrollInfo = pdfOffsetTop[activeIndex] || { scrollTop: 0 };
    const { pages = [] } = contractFileDetail[activeIndex] || {};

    let top = currentPdfScrollInfo.scrollTop + sealStyle.offsetTop;
    // 个人印章 宽高  3 / 1； 企业印章宽高 1 / 1
    // 判断左上角的坐标在哪一页
    const pInfo = pages.filter(i => i.top < top && i.bottom >= top)[0] || {};
    let pageNo = pInfo.pageNo || 1;

    const isIllegalPosition = pInfo.bottom - sign.height <= top;
    if (isIllegalPosition) {
      top = (top - sign.height - 10);
      pageNo = pageNo === 1 ? pageNo : pageNo - 1;
    }

    let newSealInfo = {
      ...sign,
      pageNo: pageNo,
      disabled: false,
      key: `sealed-${sign.sealId}-${+new Date()}`,
      left: sealStyle.offsetLeft,
      top: top,
      submitLeft: sealStyle.offsetLeft,
      submitTop: top,
    };

    // 获取当前操作的合同文件上的盖章列表
    let currentSealedInfo = sealedList[activeIndex] || [];

    currentSealedInfo.push(newSealInfo);
    sealedList[activeIndex] = currentSealedInfo;

    setSealedList([...sealedList]);
    setSealModalVisible(false);
  };

  // 【done】 删除某一个签章
  const onDelSeal = (sealIndex) => {
    sealedList[activeIndex].splice(sealIndex, 1);

    if (!sealedList[activeIndex] || sealedList[activeIndex].length === 0) {
      sealedList[activeIndex] = null;
      let temp = pageSealList;
      temp[activeIndex] = null;
      setPageSealList([...temp]);
    }

    setSealedList([...sealedList]);
  }

  useEffect(() => {
    init();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  // 【done】展示详情弹窗
  const showDetailModal = () => {
    setDetailModalVisible(true);
  };

  // 【done】展示签字弹窗
  const showSealModal = () => {
    // 如果本地有盖章数据，则不重复请求
    if (sealList.length > 0) {
      setSealModalVisible(true);
    } else {
      getSealListApi(currentPartyInfo.contractPartyType, currentPartyInfo.enterpriseName)
        .then((data) => {
          data = (data || []).map(i => {
            i.signingMode = '01';
            i.contractPartyType = currentPartyInfo.contractPartyType;
            if (currentPartyInfo.contractPartyType === '01') {
              i.width = sealStyle.width;
              i.height = sealStyle.height;
            } else {
              i.width = enterpriseSealStyle.width;
              i.height = enterpriseSealStyle.height;
            }
            return i;
          })
          setSealList(data);
          setSealModalVisible(true);
        }).catch(() => { });
    }
  };

  // 更新盖章坐标
  const onUpdateSealPosition = (params) => {
    let tempSealList = sealedList[activeIndex] || [];

    tempSealList = tempSealList.map((i) => {
      if (i.key === params.key) {
        i = {
          ...i,
          ...params,
        }
      }
      return i;
    });

    sealedList[activeIndex] = tempSealList;

    setSealedList([...sealedList]);
  };

  // 签合同
  const sign = () => {
    // 签章数据处理 && 合法性校验
    let formatSealedList = [];
    (contractFileList || []).forEach((i, index) => {
      const sealInfo = formatSealListByObjectId(index);

      if (sealInfo && sealInfo.length > 0) {
        const cSealInfo = pageSealList[index] || {};
        formatSealedList.push({
          objectName: i.objectName,
          fileName: i.fileName,
          signingMode: cSealInfo.signingMode,
          sealId: cSealInfo.sealId,
          sealObjectName: cSealInfo.sealObjectName,
          cfcaSignLocationReqs: sealInfo,
        });
      }
    });

    if (!formatSealedList || formatSealedList.length === 0) {
      // 没有盖章
      Toast.show({
        content: '至少盖一个章',
      });
      return;
    };

    // 3. 数据缓存到本地
    window.$store.set('signInfo', {
      cId: contractInfo.id,
      sId: id,
      partyInfo: currentPartyInfo,
      submitInfo: formatSealedList,
    });

    // 4. 去到选择认证方式页
    navigate(`/signCertify/${id}`);
  };

  // 格式化坐标数据
  const formatSealListByObjectId = (index) => {
    const sealInfo = sealedList[index] || [];
    let tempSealInfo = sealInfo.filter(i => !i.disabled);

    if (!tempSealInfo || tempSealInfo.length === 0) {
      return null;
    } else {
      const { scale, pages } = contractFileDetail[index] || { scale: 1 };
      let signLocationLBX = 0,
        signLocationLBY = 0,
        signLocationRUX = 0,
        signLocationRUY = 0;
      return tempSealInfo.map(i => {
        // 左下X
        signLocationLBX = i.submitLeft;
        // 右上X
        signLocationRUX = i.submitLeft + i.width;
        // 右上Y
        signLocationRUY = pages[i.pageNo - 1].bottom - i.submitTop;
        // 左下Y
        signLocationLBY = signLocationRUY - i.height;

        return {
          signOnPage: `${i.pageNo}`,
          signLocationLBX: `${Math.floor(signLocationLBX * scale)}`,
          signLocationLBY: `${Math.floor(signLocationLBY * scale)}`,
          signLocationRUX: `${Math.floor(signLocationRUX * scale)}`,
          signLocationRUY: `${Math.floor(signLocationRUY * scale)}`,
        };
      })
    };
  };

  // 【done】下载合同
  const download = () => {
    const currentFile = contractFileList[activeIndex];
    Toast.show({
      icon: 'loading',
      content: '下载中',
      maskClickable: false,
      duration: 0,
    });
    return getFileDownloadApi(currentFile.objectName).then((fileStream) => {
      const blob = new Blob([fileStream.data]);
      const url = window.URL.createObjectURL(blob);
      saveAs(url, currentFile.fileName);

      Toast.clear();
      Toast.show({
        icon: 'success',
        content: '下载成功',
      });
    }).catch((err) => {
      Toast.clear();
      Toast.show({
        content: '下载文件失败，请稍候重试',
      });
    });
  };

  // 【done】切换展示的合同
  const changeDisplayFile = (id) => {
    const index = contractFileList.findIndex(item => item.id === id);
    setActiveIndex(index);

    // 切换展示的文件
    swiperRef.current.swipeTo(index);
  };

  const canSubmitSign = () => {
    if (!sealedList || sealedList.length === 0) {
      return false;
    }
    return sealedList.length > 0;
  };

  // 继续盖章
  const continueSign = () => {
    const sign = pageSealList[activeIndex];
    if (!sign) {
      showSealModal();
    } else {
      selectSeal(sign);
    }
  };

  // 【done】渲染底部
  const renderFooterButton = () => {
    let btns = [];
    if (contractInfo.id) {
      btns.push((
        <div key="details" className={styles.detail} onClick={showDetailModal}>
          <BankcardOutline className={styles.icon} />
          <div>详情</div>
        </div>
      ));
    };

    if (!loadCompleted) {
      // 加载中
      btns.push((
        <Button
          link
          key={'loading'}
          shape='rounded'
          style={{ color: '#ff3141' }}
          className={styles.big}>
          <DotLoading color='currentColor' />
          <span>合同文件加载中</span>
        </Button>
      ));
    } else if (canSignContract(currentPartyInfo)) {
      // 只有合同状态是签署中的合同才能签字
      if (canSubmitSign()) {
        // 已经开始签了
        btns.push((
          <div
            key={'continueSign'}
            className={styles.footerItem}
            onClick={continueSign}>
            <EditSOutline className={styles.icon} />
            <div>继续盖章</div>
          </div>
        ));

        btns.push((
          <Button
            block
            key={'sign'}
            shape='rounded'
            color='primary'
            className={styles.big}
            onClick={sign}
          >
            <EditSOutline className={styles.icon} style={{ marginRight: 15 }} />
            <span>提交</span>
          </Button>
        ));

      } else {
        btns.push((
          <Button
            block
            key={'showSealModal'}
            shape='rounded'
            color='primary'
            className={styles.big}
            onClick={showSealModal}>
            <EditSOutline className={styles.icon} />
            <span>签署合同</span>
          </Button>
        ));
      }
    };

    return (
      <div className={styles.footer}>
        {btns}
      </div>
    );
  };

  const onLoad = (index) => {
    let temp = loadStatusArray;
    temp[index] = true;
    setLoadStatusArray([...temp]);

    if (temp.length === contractFileList.length) {
      const status = temp.every(i => i === true);
      Toast.clear();
      if (status) {
        setLoadCompleted(true);
      } else {
        setLoadCompleted(true);
      }
    }
  };

  // todo 临时方案
  const onUpdatePdfScroll = (params) => {
    let temp = pdfOffsetTop;
    temp[activeIndex] = params;
    setPdfOffsetTop([...temp]);
  };

  // render Tabs
  const renderTabs = () => {
    if (!contractFileList) {
      return null;
    }
    return (
      <Tabs
        activeKey={contractFileList[activeIndex].id}
        onChange={changeDisplayFile}
      >
        {contractFileList.map((i, index) => (
          <Tabs.Tab title={i.fileName} key={i.id} />
        ))}
      </Tabs>
    );
  };

  // 去创建个人签名
  const showCreateSealModal = () => {
    if (sealList && sealList.length >= 10) {
      Toast.show({
        icon: 'fail',
        content: '您最多能够拥有10个签章'
      });
      return;
    };
    setCreateSealModalVisible(true);
    setSealModalVisible(false);
  };

  // 创建个人印章成功
  const createSignatureSuccess = (data) => {
    setCreateSealModalVisible(false);
    // 设置印章的样式
    data.width = sealStyle.width;
    data.height = currentPartyInfo.contractPartyType === '01' ? sealStyle.height : enterpriseSealStyle.height;

    // 添加到可以选择的印章列表
    setSealList([...sealList, data]);
    // 将个人印章贴到PDF上
    selectSeal(data);
  };

  // 【done】渲染PDF文件
  const renderPdfArea = () => {
    if (!contractFileList) {
      // 数据还没回来
      return null;
    }
    return (
      <Swiper
        indicator={() => null}
        allowTouchMove={false}
        ref={swiperRef}
      >
        {contractFileList.map((i, index) => (
          <Swiper.Item key={i.id}>
            <PDFActionCard
              index={index}
              currentDisplayIndex={activeIndex}
              pdfInfo={contractFileDetail[index]}
              sealedInfo={sealedList[index]}
              sealedLength={(sealedList[index] || []).length}
              onDelSeal={onDelSeal}
              onLoad={() => { onLoad(index); }}
              onUpdateSealPosition={(params) => { onUpdateSealPosition(params, index) }}
              onScroll={onUpdatePdfScroll}
            />
          </Swiper.Item>
        ))}
      </Swiper>
    );
  };

  // [done] 拒绝合同
  const showRefuseModal = () => {
    form.setFieldValue('refuseReason', '');
    setDetailModalVisible(false);
    setRefuseModalVisible(true);
  };

  // 拒绝合同
  const refuse = () => {
    const values = form.getFieldsValue();

    const reason = values.refuseReason.replace(/(^\s*)|(\s*$)/g, '');

    // 数据格式校验
    if (!reason) {
      return;
    }
    updateRefuseContractApi(currentPartyInfo.id, { reasonForRefusal: reason }).then(() => {
      Toast.show({
        content: '拒绝成功',
        duration: 1500,
        afterClose: () => {
          // 刷新页面
          setRefuseModalVisible(false);
          init();
        },
      });
    }).catch(() => { })
  };

  return (
    <div className={styles.body}>
      <div className={styles.pdfDisplayContent}>
        <div className={styles.tabsContent}>
          {renderTabs()}
        </div>
        <div className={styles.pdfContent}>
          {renderPdfArea()}
        </div>
        <div className={styles.btnContent}>
          {renderFooterButton()}
        </div>
      </div>

      {/* [done] 详情弹窗 */}
      <Popup
        maskClickable={false}
        showCloseButton={true}
        visible={detailModalVisible}
        bodyStyle={{ minHeight: '80vh' }}
        onClose={() => {
          setDetailModalVisible(false);
        }}
      >
        {detailModalVisible ?
          (
            <Detail
              contractInfo={contractInfo}
              download={download}
              refuse={showRefuseModal}
            />
          ) : null
        }
      </Popup>

      {/* [done] 拒绝合同 */}
      <Popup
        maskClickable={false}
        showCloseButton={true}
        visible={refuseModalVisible}
        onClose={() => {
          setRefuseModalVisible(false);
        }}
      >
        {refuseModalVisible && (
          <Form
            form={form}
            layout='horizontal'
            onFinish={refuse}
            footer={
              <Button block type='submit' color='primary' size='large'>
                拒绝
              </Button>
            }
          >
            <Form.Header>
              请输入拒绝理由
            </Form.Header>
            <Form.Item
              name='refuseReason'
              label=''
              rules={[{ required: true, message: '请输入拒绝理由' }]}
            >
              <TextArea
                rows={5}
                showCount
                maxLength={100}
                placeholder='请输入...'
              />
            </Form.Item>
          </Form>
        )}
      </Popup>

      {/* [done] 选择签章弹窗 */}
      <Popup
        closeOnMaskClick={false}
        visible={sealModalVisible}
        showCloseButton={true}
        bodyStyle={{ minHeight: '30vh' }}
        onClose={() => {
          setSealModalVisible(false);
        }}
      >
        <div className={currentPartyInfo.contractPartyType === '01' ? styles.sealModal : styles.enterpriseSealModal}>
          <Grid columns={2} gap={20}>
            {/* 添加手绘章 */}
            <Grid.Item
              key={'sealmodal-create'}
              className={styles.sealCard}
              onClick={showCreateSealModal}
            >
              <EditSOutline className={styles.sealAddIcon} />
              <div className={styles.sealDesc}>添加手绘签名</div>
            </Grid.Item>
            {
              (sealList || []).map((i, idx) => (
                <Grid.Item
                  key={`sealmodal-${idx}`}
                  className={styles.sealCard}
                  onClick={() => { selectSeal(i); }}
                >
                  {i.signingMode === '02' ? (
                    <div className={styles.signTips}>仅本次有效</div>
                  ) : null}
                  <img
                    alt={''}
                    className={styles.sealImg}
                    src={`data:image/png;base64,${i.imageData}`}
                  />
                  <div className={styles.sealDesc}>{i.sealType === 1 ? '系统默认章' : '手绘签名'}</div>
                </Grid.Item>
              ))
            }
          </Grid>
        </div>
      </Popup>

      {/* 创建个人印章 */}
      {createSealModalVisible ? (
        <CreateSignature
          onClose={() => { setCreateSealModalVisible(false); }}
          onSuccess={createSignatureSuccess}
        />
      ) : null}
    </div>
  );
}

export default App;
