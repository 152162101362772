import React from 'react';
import {
  ErrorBlock,
} from 'antd-mobile'

function App() {
  return (
    <>
      <ErrorBlock
        status='empty'
        title={'网站建设中，敬请期待'}
        description={''}
      />
    </>
  );
}

export default App;