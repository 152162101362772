const set = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const get = (key) => {
  try {
    let data = window.localStorage.getItem(key);
    return JSON.parse(data);
  } catch (e) {
    return '';
  }
};

const remove = (key) => {
  window.localStorage.removeItem(key);
};

const clear = () => {
  window.localStorage.clear();
}

export default {
  set,
  get,
  clear,
  remove,
}