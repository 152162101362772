import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App.js';

import reportWebVitals from './reportWebVitals';

import initApp from './utils/init';

initApp();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
