import React, {
  useState,
  useEffect,
} from 'react';
import {
  Link,
  useParams,
  useNavigate,
} from 'react-router-dom';

import {
  Form,
  Input,
  Toast,
  Button,
  Radio,
} from 'antd-mobile';

import {
  loginApi,
  fetchUserInfoByTokenApi,
  fetchSmsCodeMobileApi,
} from '../../apis/index';

import {
  getLivingUrlApi,
} from '../../apis/faceRecognition.js'

import styles from './index.module.scss';
import logo from '../../logo.svg';

function App() {
  const [form] = Form.useForm();
  const { inviteId } = useParams();

  const [timer, setTimer] = useState();
  const [isAgreeRules, setIsAgreeRules] = useState(false);

  const navigate = useNavigate();


  const commonLoginHandle = (data) => {
    window.$token = `Bearer ${data.token}`;
    window.$store.set("loginInfo", {
      ...data,
      inviteId,
    });
    switch (data.user.status) {
      case '01':
        Toast.show({
          content: '请在手机端完成合同签署',
        });
        // 正常
        navigate(`/sign/${inviteId}`, {
          replace: true,
        });
        break;
      case '02':
        // 禁用
        Toast.show({
          content: '此账号已禁用，请联系邀请人处理。',
        });
        break;
      case '03':
        // 待实名验证
        window.$store.set('livingFrom', 'login');
        navigate(`/verify/${data.user.status}`, {
          replace: true,
        });
        break;
      case '04':
        // 待生物验证
        getLivingUrlApi().then((url) => {
          window.$store.set('livingFrom', 'login');
          window.location.href = url;
        }).catch(() => { });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const token = window.location.search.indexOf('token=') ? window.location.search.substring(7) : '';
    if (token) {
      window.$token = `Bearer ${token}`;
      fetchUserInfoByTokenApi().then((res) => {
        commonLoginHandle({
          user: res,
          token: token,
        });
      }).catch(() => {
        window.$token = '';
      });
    }
  }, []);

  const isValidPhoneNumber = (mobile) => {
    const pattern = /^1[3456789]\d{9}$/;
    return pattern.test(mobile);
  };

  const isValidisCode = (code) => {
    const regex = /^\d{6}$/;
    return regex.test(code);
  };

  const fetchSmsCodeMobileInfo = () => {
    const values = form.getFieldsValue();

    // 数据格式校验
    if (!isValidPhoneNumber(values.mobile)) {
      Toast.show({
        content: '请输入正确的手机号',
      });
      return;
    }

    fetchSmsCodeMobileApi(values.mobile).then(() => {
      // 发送验证码成功，开始倒计时
      Toast.show({
        content: '发送成功，请注意查收',
      });
      startSmsCodeTimer();
    }).catch(() => { });
  };

  const startSmsCodeTimer = () => {
    let count = 60;
    let intervalTimer = setInterval(() => {
      if (count < 0) {
        clearInterval(intervalTimer);
      } else {
        count -= 1;
        setTimer(count);
      }
    }, 1000);
  };

  const onFinish = (values) => {
    if (!isAgreeRules) {
      Toast.show({
        content: '请先阅读并同意用户协议',
      });
      return;
    }
    // 数据格式校验
    if (!isValidPhoneNumber(values.mobile)) {
      Toast.show({
        content: '请输入正确的手机号',
      });
      return;
    }
    if (!isValidisCode(values.mobileCode)) {
      Toast.show({
        content: '请输入正确的验证码',
      });
      return;
    }

    loginApi(values).then((data) => {
      commonLoginHandle(data);
    }).catch((err) => {
    });
  };


  const renderSmsCode = () => {
    if (timer >= 0) {
      return (<div>{timer}s</div>);
    } else {
      return (<a onClick={fetchSmsCodeMobileInfo}>获取验证码</a>)
    }
  };

  const checkRules = () => {
    setIsAgreeRules(!isAgreeRules);
  }

  return (
    <div className={styles.body}>
      <img className={styles.logo} src={logo} alt='' />
      {/* <p className={styles.desc}>
        <span className={styles.red}>{contractInfo.invalidCause}</span>
        给
        <span className={styles.red}>{contractInfo.iinvalidCause}</span>
        发来了
        <span className={styles.red}>《{contractInfo.name}》</span>
        <br />
        {renderSignTimeLimit()}
        请及时签署</p> */}
      <Form
        form={form}
        layout='horizontal'
        onFinish={onFinish}
        footer={
          <Button block type='submit' color='primary' size='large'>
            登录并签署
          </Button>
        }
      >
        <Form.Item
          name='mobile'
          label={'手机号'}
          rules={[{ required: true, message: '请输入正确的手机号' }]}
        >
          <Input
            placeholder='请输入'
            type='tel'
            maxLength={11}
            clearable
          />
        </Form.Item>
        <Form.Item
          name='mobileCode'
          label={'验证码'}
          extra={renderSmsCode()}
          rules={[{ required: true, message: '请输入正确的验证码' }]}
        >
          <Input
            placeholder='请输入'
            type='tel'
            maxLength={6}
          />
        </Form.Item>
      </Form>

      <div className={styles.footer}>
        <Radio
          checked={isAgreeRules}
          className={styles.radio}
          onClick={checkRules}
          style={{
            '--icon-size': '18px',
            '--font-size': '14px',
            '--gap': '6px',
          }}
        />
        <div className={styles.rules}>我已阅读并同意
          <Link to="/rules">《上上签服务协议》</Link>
          ，
          <Link to="/rules">《隐私政策》</Link>
          和
          <Link to="/rules">《数字证书使用协议》</Link>
        </div>
      </div>
    </div>
  );
}

export default App;