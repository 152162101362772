import React from 'react';
import styles from './index.module.scss';
import logo from '../../logo.svg';

function App() {
  return (
    <div className={styles.body}>
      <img src={logo} className={styles.logo} alt='' />
      <div className={styles.title}>用户协议</div>
      <ul>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
      </ul>

      <div className={styles.title}>用户协议</div>
      <ul>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
      </ul>

      <div className={styles.title}>用户协议</div>
      <ul>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
      </ul>

      <div className={styles.title}>用户协议</div>
      <ul>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
        <ol>1. 本协议是关于您注册、登录和使用百度账号服务所订立的协议。</ol>
      </ul>
    </div>
  );
}

export default App;