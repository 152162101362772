import axios from "axios"
import {
  Toast,
} from "antd-mobile";
import {
  getBaseUrl,
} from '../utils/tools.js';

const baseUrl = getBaseUrl();

const instances = axios.create({
  baseURL: baseUrl,
  timeout: 150000,
  headers: {
    Authorization: window.$token,
  }
});

// 添加请求拦截器
instances.interceptors.request.use(function (config) {
  config.headers['Authorization'] = window.$token;

  // 在发送请求之前做些什么
  Toast.show({
    icon: 'loading',
    maskClickable: false,
    duration: 0,
  });
  return config;
}, function (error) {
  // 对请求错误做些什么
  Toast.clear();
  return Promise.reject(error);
});

// instance 截器
instances.interceptors.response.use(function (response) {
  // 统一清除掉loading
  Toast.clear();
  // HTTP请求判断
  if (response.status === 200) {
    const {
      code,
      data,
      msg = '系统异常，请稍后重试',
    } = response.data || {};
    // 业务状态码判断
    switch (code) {
      case 200:
        // 业务正常
        return data;
      case 1002:
      case 1003:
      case 1004:
      case 1005:
      case 1006:
      case 1007:
        Toast.show({
          icon: 'fail',
          content: '登录状态已失效，请重新登录',
          duration: 1000,
          afterClose: () => {
            const userInfo = window.$store.get('loginInfo') || {};
            // token 失效，踢到登录页
            // window.$store.clear();
            // TOD：踢到登录页, 带着当前页面的地址，好家伙，还没有合同信息，头大
            window.location.href = `http://${window.location.host}/login/${userInfo.inviteId}`;
          }
        });
        break;
      default:
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        break;
    }
  } else {
    // TODO
  }

  return Promise.reject(response.data);
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  Toast.show({
    icon: 'fail',
    content: error.msg || '系统异常，请稍后重试',
  });
  return Promise.reject(error);
});

export default instances;
