// 获取用户token
function getToken() {
  try {
    let userInfo = window.$store.get('loginInfo') || {};

    if (userInfo.token) {
      return `Bearer ${userInfo.token}`;
    }

    return '';
  } catch (e) {
    return '';
  }
};

const dataURItoBlob = (dataURI, type) => {
  var binary = atob(dataURI.split(',')[1])
  var array = []
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: type })
}

/* 压缩base64图片 */
export function tinyImage(base64) {
  return new Promise((resolve) => {
    let newImage = new Image()
    let quality = 0.8 // 压缩系数0-1之间
    var blobData
    newImage.src = base64
    // newImage.setAttribute(‘crossOrigin’, ‘Anonymous’) // url为外域时需要
    let imgWidth, imgHeight
    newImage.onload = function () {
      imgWidth = this.width
      imgHeight = this.height
      let canvas = document.createElement("canvas")
      let ctx = canvas.getContext('2d')
      canvas.width = imgWidth
      canvas.height = imgHeight
      quality = 0.8
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(this, 0, 0, canvas.width, canvas.height)
      let base64 = canvas.toDataURL('image/png', quality) // 压缩语句
      blobData = dataURItoBlob(base64, 'image/png')
      do {
        if (quality > 0) {
          quality = quality - 0.1
        }
        imgWidth = imgWidth * quality
        imgHeight = imgHeight * quality
        canvas.width = imgWidth
        canvas.height = imgHeight
        ctx.clearRect(0, 0, imgWidth, imgHeight)
        ctx.drawImage(newImage, 0, 0, imgWidth, imgHeight)
        base64 = canvas.toDataURL('image/png', quality) // 压缩语句
        blobData = dataURItoBlob(base64, 'image/png')
      } while (blobData.size > 1024 * 1024 * 4)
      if (blobData.size < 1024 * 1024 * 4) {
        resolve(base64)
      }
    }
  })
}

export const rotateImage = (base64, angle = 0) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.height;
      canvas.height = img.width;
      const ctx = canvas.getContext("2d");
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(angle * Math.PI / 180);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);
      resolve(canvas.toDataURL("image/png").replace(/^data:image\/jpeg;base64,/, ''));
    };
    img.onerror = reject;
  });

};

const envs = {
  'mock': 'https://mock.apifox.com/m1/3595733-0-default',
  'dev': 'https://dev-cnwzt.com.cn/api/',
  'test': 'https://test-cnwzt.com.cn/api/',
  'prod': 'https://cnwzt.com.cn/api/',
};
export function getBaseUrl() {
  const hostName = window.location.hostname;
  if (hostName === 'localhost') {
    return envs.prod;
  } else if (hostName === 'mock-h5wzt.cnwzt.com.cn') {
    return envs.prod;
  } else if (hostName === 'dev-h5wzt.cnwzt.com.cn') {
    return envs.prod;
  } else if (hostName === 'test-h5wzt.cnwzt.com.cn') {
    return envs.prod;
  } else {
    return envs.prod;
  }
};

export default {
  getToken,
  tinyImage,
  rotateImage,
};