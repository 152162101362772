import React, { useEffect, useRef, useState } from 'react';
import {
  Toast,
  Grid,
  Button,
  Swiper,
  Divider,
} from 'antd-mobile';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';

import Mobile from './mobile';
import Face from './face';

function App() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [type, setType] = useState('0');
  const [submitInfo, setSubmitInfo] = useState({});
  const [partyInfo, setPartyInfo] = useState({});
  const [contractId, setContractId] = useState('');
  const swiperRef = useRef(null);

  const init = () => {
    const localSubmitInfo = window.$store.get('signInfo') || {};

    let {
      cId,
      sId,
      partyInfo = {},
      submitInfo = {},
    } = localSubmitInfo;

    if (sId !== id) {
      // 错误数据，返回
      Toast.show({
        icon: 'fail',
        content: '签署信息错误。',
      });

    } else {
      setPartyInfo(partyInfo);
      setSubmitInfo(submitInfo);
      setContractId(cId);
    }
  };

  useEffect(() => {
    init();
  }, []);

  // 根据签署人的id查询签署信息
  const changeType = (type) => {
    setType(type);

    swiperRef.current.swipeTo(type);
  };

  // 签署成功
  const onCompleted = () => {
    Toast.show({
      content: '签署成功',
      duration: 1500,
      maskClickable: false,
      afterClose: () => {
        // 移除本地的签署合同信息
        navigate(`/signResult/${id}/success`, {
          replace: true,
        });
      }
    });
  };

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ marginBottom: 20 }}>请选择认证方式</div>
      <Grid columns={3}>
        <Grid.Item>
          <Button color={`${type === '0' ? 'success' : 'default'}`} size='small' onClick={() => { changeType('0'); }}>短信验证</Button>
        </Grid.Item>
        <Grid.Item>
          <Button color={`${type === '1' ? 'success' : 'default'}`} size='small' onClick={() => { changeType('1'); }}>人脸验证</Button>
        </Grid.Item>
      </Grid>
      <Divider style={{ borderStyle: 'dashed' }} />
      <Swiper
        indicator={() => null}
        allowTouchMove={false}
        ref={swiperRef}
      >
        <Swiper.Item key={'0'}>
          <Mobile
            id={id}
            contractId={contractId}
            partyInfo={partyInfo}
            submitInfo={submitInfo}
            onCompleted={onCompleted}
          />
        </Swiper.Item>
        <Swiper.Item key={'1'}>
          <Face
            id={id}
            partyInfo={partyInfo}
            contractId={contractId}
            submitInfo={submitInfo}
            onCompleted={onCompleted}
          />
        </Swiper.Item>
      </Swiper>
    </div>
  );
};

export default App;