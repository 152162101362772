import React, { useState } from 'react';
import {
  Toast,
  Button,
  ImageUploader,
} from 'antd-mobile';
import {
  CloseCircleOutline,
} from 'antd-mobile-icons';

import frontPlaceholder from '../../../assets/images/z.png';
import backPlaceholder from '../../../assets/images/f.png';

import {
  uploadCredentialApi,
  userStandardNameVerifiedApi,
} from '../../../apis/index';

import {
  getLivingUrlApi,
} from '../../../apis/faceRecognition';

import styles from './index.module.scss';

function App() {
  const [frontPhoto, setFrontPhoto] = useState([]);
  const [backPhoto, setBackPhoto] = useState([]);
  const [verified, setVerifiedResult] = useState(false);

  const mockUpload = (file) => {
    return Promise.resolve({
      url: URL.createObjectURL(file),
      file: file,
    });
  }

  // 开始上传文件
  const startVerify = async () => {
    if (!frontPhoto || !frontPhoto[0]) {
      Toast.show({
        content: '请补充上传身份证正面照片'
      });
      return;
    }
    if (!backPhoto || !backPhoto[0]) {
      Toast.show({
        content: '请补充上传身份证反面照片'
      });
      return;
    }

    try {
      // 上传正面
      const front = frontPhoto[0];
      let frontData = {};
      if (front.url !== front.success) {
        frontData = await uploadCredentialApi(front.file);
        setFrontPhoto([{
          ...front,
          success: front.url,
        }]);
      }

      // // 上传反面
      const back = backPhoto[0];
      let backData = {};
      if (back.url !== back.success) {
        backData = await uploadCredentialApi(back.file);
        setBackPhoto([{
          ...back,
          success: back.url,
        }]);
      }

      // 实名认证接口
      if (!verified) {
        await userStandardNameVerifiedApi();

        updateLocalUserInfo({
          ...frontData,
          ...backData,
        });

        setVerifiedResult(true);
      }

      // 获取活体认证地址
      const url = await getLivingUrlApi();

      // 更新本地信息
      window.$store.set('livingFrom', 'login');

      window.location.href = url;
    } catch (err) {
      console.info('系统异常');
    }
  }

  // 更新本地的用户信息
  const updateLocalUserInfo = (data) => {
    if (data.standardName && data.credentialNumber) {
      let userInfo = window.$store.get('loginInfo') || {};

      if (userInfo && userInfo.user) {
        userInfo.user = {
          ...userInfo.user,
          ...data,
          status: '04',
        }

        window.$token = `Bearer ${userInfo.token}`;
        window.$store.set('loginInfo', userInfo);
      }
    }
    return data;
  };

  const renderCard = (originNode, file, type) => {
    return (
      <div className={styles.uploadButton}>
        <CloseCircleOutline className={styles.delBtn} onClick={() => {
          if (type === 'frontPhoto') {
            setFrontPhoto([]);
          } else if (type === 'backPhoto') {
            setBackPhoto([]);
          }
        }} />
        <img alt='' src={file.url} className={styles.uploadButton} />
      </div>
    );
  }

  return (
    <>
      <div className={styles.title}>
        请上传清晰的身份证原件正，反面照片，系统将自动识别证件信息，图片仅限<span className={styles.red}>Jpeg，JPG，PNG</span>格式，个人信息将用于申请数字证书。请<span className={styles.red}>不要上传带水印照片</span>或者<span className={styles.red}>水印文字为“用于上上签实名”水印字样</span>的照片</div>
      <div className={styles.subTitle}>
        <ImageUploader
          key={'frontPhoto'}
          accept={'image/jpeg,image/jpg,image/png'}
          maxCount={1}
          multiple={false}
          value={frontPhoto}
          onChange={setFrontPhoto}
          upload={mockUpload}
          renderItem={(o, f) => { return renderCard(o, f, 'frontPhoto'); }}
        >
          <img className={styles.uploadHolder} src={frontPlaceholder} alt='' />
        </ImageUploader>
      </div>
      <div className={styles.subTitle}>
        <ImageUploader
          key={'backPhoto'}
          accept={'image/jpeg,image/jpg,image/png'}
          maxCount={1}
          multiple={false}
          value={backPhoto}
          onChange={setBackPhoto}
          upload={mockUpload}
          renderItem={(o, f) => { return renderCard(o, f, 'backPhoto'); }}
        >
          <img className={styles.uploadHolder} src={backPlaceholder} alt='' />
        </ImageUploader>
      </div>
      <div className={styles.footer}>
        <Button block color='primary' size='large' onClick={startVerify}>
          开始实名认证
        </Button>
      </div>
    </>
  );
}

export default App;