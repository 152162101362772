import './App.css';

import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import Login from './views/login/index';
import Rules from './views/explain/rules';
import Sign from './views/sign/index';
import FaceRecognition from './views/faceRecognition/index';
import SignCertify from './views/signCertify/index';
import SignResult from './views/signResult/index';
import EmptyPages from './views/emptyPages/index';
import Verify from './views/verify/index';

const router = createBrowserRouter([{
  path: "/login",
  element: <Login />,
}, {
  path: "/login/:inviteId",
  element: <Login />,
}, {
  path: "/sign/:id",
  element: <Sign />,
}, {
  path: "/signCertify/:id",
  element: <SignCertify />,
}, {
  path: "/signResult/:id/:type",
  element: <SignResult />,
}, {
  path: "/recognition/:token",
  element: <FaceRecognition />,
}, {
  path: "/verify/:status",
  element: <Verify />,
}, {
  path: "/rules",
  element: <Rules />,
}, {
  path: "/",
  redirectTo: '/login'
}, {
  path: '*',
  element: <EmptyPages />,
}]);

function App() {
  return (
    <RouterProvider router={router} />
  );
};

export default App;
