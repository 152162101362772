import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {
  Radio,
  Button,
} from 'antd-mobile';
import {
  CloseCircleFill,
  CheckCircleFill,
} from 'antd-mobile-icons';
import {
  postFileUploadBash64Api,
} from '../../apis/faceRecognition';
import {
  rotateImage,
} from '../../utils/tools'
import styles from './index.module.scss';

function App(props) {
  const {
    onClose = () => { },
  } = props;
  const signCanvas = useRef({});
  const [color, setColor] = useState('black');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
  }, []);

  const clear = () => {
    // 提交中不允许清除
    if (loading) {
      return;
    }
    signCanvas.current.clear();
  };

  const create = () => {
    if (signCanvas.current.isEmpty()) {
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    let base64Img = signCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    rotateImage(base64Img, -90).then((img) => {
      img = img.substring(22);
      postFileUploadBash64Api(img)
        .then((data) => {
          props.onSuccess({
            imageData: img,
            sealObjectName: data.objectName,
            signingMode: '02',
          });
          setLoading(false);
        }).catch((e) => {
          setLoading(false);
        });
    });
  };
  return (
    <div className={styles.signWrapper}>
      <div className={styles.closeBtn} onClick={onClose}>
        <CloseCircleFill />
      </div>
      <div className={styles.signWriteContent}>
        {/* 背景 */}
        <div className={styles.signBg}>
          <div className={styles.txt}>签名区</div>
        </div>
        {/* 签名区 */}
        <SignatureCanvas
          penColor={color}
          ref={signCanvas}
          maxWidth={3}
          velocityFilterWeight={0.01}
          canvasProps={{ className: styles.signCanvas }}
        />
      </div>
      <div className={styles.signBtnGroup}>
        <div className={styles.leftBtnWrapper}>
          <Radio.Group value={color} onChange={setColor}>
            {['black', 'red'].map((i) => {
              return (
                <Radio
                  value={i}
                  block
                  icon={checked => (
                    <CheckCircleFill
                      className={checked ? styles.checkedRadio : styles.unCheckedRadio}
                      style={{ color: i, backgroundColor: checked ? '' : i }} />
                  )}
                />
              )
            })}
          </Radio.Group>
        </div>
        <div className={styles.rightBtnWrapper}>
          <Button
            onClick={clear}
            className={styles.btn}>清除</Button>
          <Button
            onClick={create}
            loading={loading}
            className={styles.btn}
            color="danger">保存</Button>
        </div>
      </div>
    </div >
  );
};

export default App;