import React, { useState } from 'react';
import {
  Toast,
  Image,
  Skeleton,
} from 'antd-mobile';
import Draggable from 'react-draggable';
import { throttle } from 'lodash';
import styles from './index.module.scss';

const w = window.innerWidth;

function App(props) {
  const {
    index, // 此PDF的序号
    currentDisplayIndex, // 当前正在展示的PDF
    pdfInfo = [],
    sealedInfo = [],
  } = props || {};

  const [touchStartInfo, setTouchStartInfo] = useState({});
  const [loadStatusArray, setLoadStatusArray] = useState([]);

  const [delSealKey, setDelSealKey] = useState('');
  const [pageInfo, setPageInfo] = useState({ pageNo: 1 });

  const handleStart = (e) => {
    setDelSealKey('');
    setTouchStartInfo(e);
  }

  const formatOffset = (str) => {
    try {
      if (!str) {
        return;
      }

      const reg = new RegExp("[translate(|,|)]");
      let arr = str.split(reg)
        .join(' ')
        .replace(/\s+/g, '')
        .split('px');

      return {
        submitLeft: Math.floor(Number(arr[0]) + Number(arr[2])),
        submitTop: Math.floor(Number(arr[1]) + Number(arr[3]))
      }

    } catch (err) {
      return;
    }
  }

  // 更改对应元素的坐标
  const handleStop = (e, ui, seal) => {
    if (e.timeStamp - (touchStartInfo.timeStamp || e.timeStamp) < 150) {
      setDelSealKey(seal.key);
    } else {
      setDelSealKey('');
      const newPosition = formatOffset(`${ui.node.style.transform}`);

      // 判断左上角的坐标在哪一页
      const pInfo = pdfInfo.pages.filter(i => i.top < newPosition.submitTop && i.bottom >= newPosition.submitTop)[0] || {};

      const isIllegalPosition = pInfo.bottom - seal.height <= newPosition.submitTop;
      if (isIllegalPosition) {
        Toast.show({
          content: '这个印章位置不会生效哦'
        })
      }
      props.onUpdateSealPosition({
        pageNo: pInfo.pageNo,
        key: seal.key,  // 盖章信息
        disabled: isIllegalPosition,
        ...newPosition,
      });
    }
    setTouchStartInfo({});
  }

  const throttleOnScroll = throttle((e) => {
    onScroll(e);
  }, 300);

  const onScroll = (e) => {
    const { scrollTop } = e.target;
    const { pages } = pdfInfo;

    const currentPage = pages.filter((i) => {
      return i.top + i.halfHeight >= scrollTop;
    })[0] || pageInfo;

    setPageInfo(currentPage);
    props.onScroll({
      scrollTop,
      pageInfo: currentPage,
    });
  }

  const onLoad = (idx) => {
    const arr = loadStatusArray;
    arr[idx] = true;
    setLoadStatusArray([...arr]);
    const { totalPages } = pdfInfo;
    if (arr.length === totalPages) {
      const status = arr.every(i => i === true);
      if (status) {
        // 全部加载完成
        props.onLoad && props.onLoad();
      } else {
        props.onLoad && props.onLoad();
      }
    }
  };

  const onError = () => {
    props.onError && props.onError();
  };

  // 不展示删除按钮
  const onClickPdfAction = () => {
    setDelSealKey('');
  }

  if (index === currentDisplayIndex) {

  } else if (delSealKey !== '') {
    setDelSealKey('');
  }

  // 合同信息
  if (!pdfInfo || !pdfInfo.pages || pdfInfo.pages.length < 1) {
    return (
      <Skeleton.Paragraph
        className={styles.customSkeleton}
        lineCount={15}
        animated
      />
    );
  }

  return (
    <div>
      {/* 页码 */}
      <div className={styles.pageNo}>
        {(pageInfo || {}).pageNo || 1} / {pdfInfo.totalPages || 1}
      </div>
      <div className={styles.pdfWrapper} onScroll={throttleOnScroll}>
        <div onClick={onClickPdfAction} className={styles.wrapper}>
          {(pdfInfo.pages || []).map((i, idx) => (
            <div
              key={`${index}-list-${idx}`}
              className={styles.listItem}
            >
              <Image
                alt=''
                width={pdfInfo.displayWidth}
                height={i.displayHeight}
                src={`${i.imageUrl}`}
                onError={() => { onError(idx) }}
                onLoad={() => { onLoad(idx) }}
                placeholder={(
                  <Skeleton.Paragraph
                    className={styles.customSkeleton}
                    lineCount={20}
                    animated
                  />
                )}
                fallback={(
                  <Skeleton.Paragraph
                    className={styles.customSkeleton}
                    lineCount={20}
                    animated
                  />
                )}
              />
            </div>
          ))}
        </div>
        {
          (sealedInfo || []).map((i, idx) => (
            <Draggable
              key={i.key}
              handle=".handle"
              position={null}
              scale={1}
              bounds={{
                top: -1 * i.top + 10,
                bottom: pdfInfo.displayHeight - i.top - 30,
                left: -1 * i.left + 10,
                right: w - i.left - 70,
              }}
              positionOffset={{ x: i.left, y: i.top }}
              onStart={(e, ui) => {
                handleStart(e, ui, i);
              }}
              onStop={(e, ui) => {
                handleStop(e, ui, i);
              }}
            >
              <div className={i.contractPartyType === '01' ? styles.dragSeal : styles.enterpriseDragSeal}>
                <div className="handle">
                  <img
                    src={`data:image/png;base64,${i.imageData}`}
                    alt={''}
                    width={i.width}
                    height={i.height}
                    style={{ background: `${i.disabled ? 'gray' : ''}` }}
                  />
                </div>
                {delSealKey === i.key ? (<div className={styles.sealDel} onClick={() => {
                  props.onDelSeal(idx);
                }}>删除</div>) : null}
              </div>
            </Draggable>
          ))
        }
      </div>
    </div>
  );
};

export default App;