import React, { createElement, useState } from 'react';
import {
  useParams,
} from 'react-router-dom';

import {
  ErrorBlock,
} from 'antd-mobile';

import Personal from './personal/index';

function App() {
  const { status } = useParams();

  if (status === '03') {
    return (
      <Personal />
    );
  } else if (status === '04') {
    // 活体认证, 好像暂时不需要在这里
  } else {
    return <ErrorBlock title={'非法业务'} description={''} />
  }
}

export default App;