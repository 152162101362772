// 接口文档： https://www.yuque.com/springgreen/qiong/tawks8s27g8e9mt1
import api from './axios';

// 根据邀请ID获取合同信息
export function fetchContractInfoByInviteId(inviteId) {
  return Promise.resolve({});
};

// 发送手机验证码
export function fetchSmsCodeMobileApi(mobile) {
  return api.post('/sms/code/mobile', { mobile: mobile });
};

// 根据token 查询用户信息
export function fetchUserInfoByTokenApi() {
  return api.get('/user/token');
};

// 用户登录
export function loginApi(params) {
  return api.post('/user/login', params);
};

// 上传用户证件照
export function uploadCredentialApi(file) {
  const formData = new FormData();
  formData.append('file', file);
  return api.post('/user/upload/credential', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// 实名认证
export function userStandardNameVerifiedApi() {
  return api.post('/user/standardName/verified');
};

// 根据参与人ID查询参与人信息
export function getContractInfoByPartyIdApi(id) {
  return api.get(`/contractParty/info/${id}`);
};

// 根据ID查询合同信息
export function getContractInfoByIdApi(id) {
  return api.get(`/contract/${id}`);
}

// 根据合同id查合同资源
export function getFileListByBusinessApi(businessID) {
  return api.get(`/file/list/${businessID}/03`);
};

// 根据合同id查附件资源
export function getAttachmentListByBusinessApi(businessID) {
  return api.get(`/file/list/${businessID}/04`);
};

// 根据合同资源名称查地址
export function getFileUrlListByFileIdApi(objectName) {
  return api.get(`/file/pdf2png`, { params: { objectName: objectName } });
};

// 根据合同查询参与人信息
export function getContractPartyByContractIdApi(contractId) {
  return api.get(`/contractParty/${contractId}`);
};

// 根据合同查询抄送人信息
export function getContractCCByContractIdApi(contractId) {
  return api.get(`/contractCc/${contractId}`);
};

// 拒绝合同
export function updateRefuseContractApi(id, params) {
  return api.put(`/contractParty/${id}/refuse`, params);
};

// 获取签章列表
export function getSealListApi(type, name) {
  // “01”：个人 “02”：企业
  if (type === '02') {
    return api.get('/cfca/enterprise/seal', { params: { enterpriseName: name } });
  } else {
    return api.get('/cfca/my/seal');
  }
};

// 签署合同
export function putContractSignApi(params) {
  return api.post(`/contract/sign`, params);
};

// 根据合同ID发送验证码【测试专用】
export function postCfcaSendProjectCodeApi(params) {
  return api.post(`/cfca/sendProjectCode`, params);
};

// 合同参与人签字
export function putSignApi(id, params) {
  return api.put(`/contractParty/${id}/sign`, params);
};


export default {};
