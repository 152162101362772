import React from 'react';

import {
  Tag,
  Toast,
} from 'antd-mobile';
import {
  MailFill,
  UndoOutline,
  TravelOutline,
  FingerdownOutline,
  CheckCircleOutline,
  ClockCircleOutline,
  FaceRecognitionOutline,
  ArrowDownCircleOutline,
} from 'antd-mobile-icons';

import {
  isSignEnum,
  isSignContract,
  operateAbleContract,
  contractStatusEnum,
} from '../../../utils/const.js';

import styles from './index.module.scss';

function App(props) {
  const {
    contractInfo = {},
  } = props || {};

  // 下载功能
  const download = () => {
    props.download();
  };

  const refuse = () => {
    props.refuse();
  };

  const renderContractHeader = () => {
    return (
      <div className={styles.detailTitle}>
        <div className={styles.left}>
          <MailFill fontSize={50} />
        </div>
        <div className={styles.right}>
          <div className={styles.name}>{contractInfo.name}</div>
          <div className={styles.id}>
            <span>{contractInfo.code}</span>
            <Tag className={styles.tag} color='warning'>{contractStatusEnum[contractInfo.status]}</Tag>
          </div>
        </div>
      </div>
    );
  };

  // 发起人信息
  const renderStartInfo = () => {
    return (
      <div className={styles.timelineItemActive}>
        <FaceRecognitionOutline className={styles.icon} style={{
          color: 'var(--adm-color-success)',
        }} />
        <div className={styles.desc}>
          <div className={styles.timeTitle}>发起合同</div>
          <div>{(contractInfo.entryUser || {}).standardName}</div>
        </div>
        <div className={styles.time}>{contractInfo.createTime}</div>
      </div>
    );
  };

  // 签署人信息
  const renderPartyInfo = () => {
    return (contractInfo.contractPartys || []).map(i => (
      <div key={i.userName} className={isSignContract(i) ? styles.timelineItemActive : styles.timelineItem}>
        {isSignContract(i) ? (<CheckCircleOutline className={styles.icon} />) : (<ClockCircleOutline className={styles.icon} />)}

        <div className={styles.desc}>
          <div className={styles.timeTitle}>{i.title} <Tag className={styles.tag} color='warning'>{isSignEnum[i.isSign] || ''}</Tag></div>
          <div>{i.userName}</div>
          {/* 撤回理由 */}
          {i.reasonForRefusal ? (
            <Tag
              fill='outline'
              style={{
                '--border-color': '#efefef',
                '--background-color': '#efefef'
              }}
            >
              撤回理由：{i.reasonForRefusal}
            </Tag>
          ) : null}
        </div>
        <div className={styles.time}>{i.signTime}</div>
      </div>
    ));
  };

  // 抄送人信息
  const renderCCInfo = () => {
    if (!contractInfo.contractCcs || contractInfo.contractCcs.length < 1) {
      return null;
    }

    return (
      <div className={styles.timelineItemActive}>
        <TravelOutline className={styles.icon} />
        <div className={styles.desc}>
          <div className={styles.timeTitle}>抄送人</div>
          <div>{contractInfo.contractCcs.map(i => i.userName).join('、')}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.detailModal}>
      <div className={styles.header}>
        <div className={styles.subTitle}>签署文件</div>
        {renderContractHeader()}
      </div>

      <div className={styles.content}>
        <div className={styles.subTitle}>时间流程详情</div>
        <div>
          {/* 发起人列表 */}
          {renderStartInfo()}

          {/* 签署人列表 */}
          {renderPartyInfo()}

          {/* 抄送人列表 */}
          {renderCCInfo()}
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.detail} onClick={download}>
          <ArrowDownCircleOutline className={styles.icon} />
          <div>下载</div>
        </div>
        {
          operateAbleContract(contractInfo) && (
            <div className={styles.detail} onClick={refuse}>
              <UndoOutline className={styles.icon} />
              <div>拒绝</div>
            </div>
          )
        }
        <div className={styles.detail}>
          <FingerdownOutline className={styles.icon} onClick={() => {
            Toast.show({
              content: '还没想好怎么写，先放着吧'
            });
          }} />
          <div>附件</div>
        </div>
      </div>
    </div>
  )
};

export default App;