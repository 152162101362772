// 认证类型
export const contractPartyType = {
  '01': '个人',
  '02': '企业',
};

// 证件类型
export const credentialTypeEnum = {
  '01': '居民身份证',
  '02': '中国公民护照',
  '03': '台湾居民来往大陆通行证',
  '04': '港澳居民来往内地通行证',
  '05': '外国公民护照',
  '06': '户口簿',
  '07': '统一社会信用代码证',
};

// 用户账号状态 
export const userStatusEnum = {
  '01': '正常',
  '02': '禁用',
  '03': '待实名验证',
  '04': '待生物验证',
};
// 合同状态
export const canSignContract = (info) => {
  return info.isSign === '02';
};

export const operateAbleContract = (info) => {
  return info.status == '02';
}

// 合同状态
export const contractStatusEnum = {
  '01': '草稿',
  '02': '签署中',
  '03': '已撤销',
  '04': '已拒绝',
  '05': '已过期',
  '06': '已完成',
};

// 签署方类型
export const partyTypeEnum = {
  '01': '单方签署',
  '02': '双方签署',
  '03': '多方签署'
};

// 多方签署 => 是否按照顺序签署
export const isOrderEnum = {
  '01': '按顺序签署',
  '02': '无顺序签署'
};

// 用户是否已经签署了合同
export const isSignContract = (i) => {
  return i.isSign === '01';
};

// 签署状态
export const isSignEnum = {
  '01': '已签署',
  '02': '未签署',
  '03': '拒绝签署',
};