import React from 'react';
import {
  ResultPage
} from 'antd-mobile';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';

function App() {
  const { id, type } = useParams();
  const navigate = useNavigate();

  const typeEnum = {
    fail: {
      status: 'error',
      title: '签署失败',
      description: '如需帮助，请联系合同发起人进行处理',
    },
    success: {
      status: 'success',
      title: '签署成功',
      description: '已成功为您调用数证书完成签署',
      primaryButtonText: '下载 / 查看合同',
      onPrimaryButtonClick: () => {
        navigate(`/sign/${id}`, {
          replace: true,
        });
      },
    }
  };

  const info = typeEnum[type] || {};

  return (
    <ResultPage
      status={info.status}
      title={info.title}
      description={info.description}
      primaryButtonText={info.primaryButtonText}
      onPrimaryButtonClick={info.onPrimaryButtonClick}
    />
  );
}

export default App;