import axios from 'axios';
import {
  getBaseUrl,
} from '../utils/tools.js';

const baseUrl = getBaseUrl();

// 下载PDF文件
export function getFileDownloadApi(objectName) {
  return axios.get(
    `${baseUrl}/file/download`,
    {
      params: { objectName: objectName },
      headers: { Authorization: window.$token },
      responseType: 'arraybuffer'
    },
  );
};