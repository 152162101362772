import api from './axios';

// 【CFCA】 获取人脸识别地址 - 登录流程
export function getLivingUrlApi() {
  return api.get('/user/living/url');
};

// 【CFCA】人脸识别结果核验
export function postUserStandardNameVerifiedApi() {
  return api.post('/user/living/verified');
}

// 【CFCA】 人脸识别刷新token
export function getUserLivingVerifiedResultApi() {
  return api.get(`/user/living/verified/result`);
};

// 【CFCA】 生成手绘签
export function postFileUploadBash64Api(fileBase64) {
  return api.post('/file/upload/bash64', {
    bash64Str: fileBase64,
    fileType: '.png',
  });
};