import React from 'react';
import {
  ErrorBlock,
} from 'antd-mobile';

function App() {
  return (<div style={{ width: '80%', margin: '30px auto 100px' }}>
    <ErrorBlock
      status='empty'
      title="功能完善中"
      description="请先使用短信验证方式提交"
    />
  </div>
  );
};

export default App;