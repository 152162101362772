import React, { useState, useEffect } from 'react';
import {
  Toast,
  ResultPage,
} from 'antd-mobile';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';

import {
  getUserLivingVerifiedResultApi,
  postUserStandardNameVerifiedApi,
} from '../../apis/faceRecognition';

import styles from './index.module.scss';

function App() {
  const { token } = useParams();
  const [result, setResult] = useState({});
  const from = window.$store.get('livingFrom');
  const userInfo = window.$store.get('loginInfo');

  const navigate = useNavigate();

  useEffect(() => {
    getUserStandardNameVerified();
  }, []);

  // 获取人脸识别结果
  const getUserStandardNameVerified = () => {
    window.$token = `Bearer ${token}`;
    postUserStandardNameVerifiedApi().then((data) => {
      window.$store.remove('livingFrom')
      setResult(data);
      if (from === 'login') {
        getUserLivingVerifiedResultApi().then((res) => {
          window.$token = `Bearer ${res.token}`;
          window.$store.set("loginInfo", {
            ...res,
            inviteId: userInfo.inviteId,
          });
          // 刷新用户token
          Toast.show({
            content: '即将为您跳转到签署页面',
            duration: 1500,
            afterClose: () => {
              // 登录跳转过来的
              navigate(`/sign/${userInfo.inviteId}`, {
                replace: true,
              });
            },
          });
        }).catch(() => { })
      } else if (from === 'sign') {
        // 签名跳转过来的
        Toast.show({
          content: '即将为您跳转到签署页面',
          duration: 1500,
          afterClose: () => {
            // 登录跳转过来的
            navigate(`/sign/${userInfo.inviteId}`, {
              replace: true,
            });
          },
        });
      } else {

      }
    }).catch(() => { });
  };

  if (!result || !result.platformCode) {
    return null;
  }

  return (
    <div className={styles.body}>
      <ResultPage
        status={result.platformCode === '001000000' ? 'success' : 'error'}
        title={result.platformDesc}
        description=''
      // primaryButtonText='去官网'
      >
      </ResultPage>
    </div>
  );
}

export default App;